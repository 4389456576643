
  import React, { useState } from "react";
import "./css/ToolTipEditor.css";

function TooltipEditor({ onSave, onClose, imageUrl }) {
  const [tooltip, setTooltip] = useState({
    title: "",
    subtitle: "",
    description: "",
    links: ["", ""],
    innerImage: ""
  });
  const [preview, setPreview] = useState(null);


  const isFormValid = () => {
    return (
      tooltip.title.trim() !== "" &&
      tooltip.subtitle.trim() !== "" &&
      // tooltip.iconStyle !== "" &&
      // tooltip.size !== "" &&
      // tooltip.color !== "" &&
      tooltip.links.every(link => link.trim() !== "")
    );
  };

  const handleChange = (e) =>
    setTooltip({ ...tooltip, [e.target.name]: e.target.value });

  const handleSave = () => {
    if (isFormValid()) {
      onSave(tooltip);
    } else {
      alert("Please fill in all required fields");
    }
  };

  // const handleInnerImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setTooltip({ ...tooltip, innerImage: reader.result });
  //     };
  //     reader.readAsDataURL(file); // Convert file to base64 string for storage
  //   }
  // };


  const handleInnerImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);

      const reader = new FileReader();
      reader.onloadend = () => {
        setTooltip({ ...tooltip, innerImage: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <button style={closeButtonStyle} onClick={onClose}><b>X</b></button>
        <div className="tooltip-main">
          <div className="tooltip-form">
            {/* Display innerImage if available, otherwise fallback to imageUrl */}
            {(tooltip.innerImage || imageUrl) && (
              // <img
              //   className="tooltip-image"
              //   src={tooltip.innerImage || imageUrl}
              //   alt="Tooltip"
              //   style={{
              //     width: tooltip.size === "small" ? "200px" :
              //           tooltip.size === "medium" ? "350px" : 
              //           tooltip.size === "large" ? "500px" : "600px"
              //   }}
              // />
              <label htmlFor="imageUpload" className="upload-boxx">
              <input
              type="file"
              accept="image/*"
              onChange={handleInnerImageChange}
              id="imageUpload"
              className="hidden-input"
            />
           {preview || tooltip.innerImage ? (
      <img
        src={preview || tooltip.innerImage}
        alt="Preview"
        style={{
          maxWidth: '97%',
          maxHeight: '440px',
          objectFit: 'contain'
        }}
      />
    ) : (
      <div className="upload-placeholder">
        <p>Drag and Drop image here</p>
        <p>or</p>
        <span>Upload from Computer</span>
      </div>
    )}
            </label>
            )}
            <div className="tooltip-main-fields">
              <div style={{ flexDirection: "column", display: 'flex' }}>
                <input
                  className="tooltip-input"
                  name="title"
                  placeholder="Title"
                  onChange={handleChange}
                  style={{
                    fontSize: "1.5rem",
                  }}
                />
                <input
                  className="tooltip-input"
                  name="subtitle"
                  placeholder="Subtitle"
                  onChange={handleChange}
                  style={{
                    fontSize: "1rem",
                  }}
                />
                <textarea
                  className="tooltip-input tooltip-textarea"
                  name="description"
                  placeholder="Description"
                  onChange={handleChange}
                  style={{
                    fontSize: "0.8rem",
                  }}
                />
              </div>
              {/* <div className="tooltip-links">
                {tooltip.links.map((_, i) => (
                  <input
                    key={i}
                    type="link"
                    className="tooltip-input"
                    placeholder={`URL ${i + 1}`}
                    onChange={(e) => {
                      const newLinks = [...tooltip.links];
                      newLinks[i] = e.target.value;
                      setTooltip({ ...tooltip, links: newLinks });
                    }}
                  />
                ))}
              </div> */}

<div className="tooltip-links">
    <div>
        <label style={{ fontWeight: 'bold', color:"grey",display: 'block', marginBottom: '5px' }}>Inquire More</label>
        <input
            key={0}
            type="link"
            className="tooltip-input"
            placeholder="URL 1"
            onChange={(e) => {
                const newLinks = [...tooltip.links];
                newLinks[0] = e.target.value;
                setTooltip({ ...tooltip, links: newLinks });
            }}
        />
    </div>
    <div>
        <label style={{ fontWeight: 'bold',  color:"grey",display: 'block', marginBottom: '5px' }}>Contact Us</label>
        <input
            key={1}
            type="link"
            className="tooltip-input"
            placeholder="URL 2"
            onChange={(e) => {
                const newLinks = [...tooltip.links];
                newLinks[1] = e.target.value;
                setTooltip({ ...tooltip, links: newLinks });
            }}
        />
    </div>
</div>


      

              {/* Other Options */}
  
              <div className="finishButton">
                {/* <button className="save-tooltip-btn-cls" onClick={onClose}>
                  Close
                </button> */}
                <button
                  className={`save-tooltip-btn ${!isFormValid() ? 'disabled' : ''}`}
                  onClick={handleSave}
                  disabled={!isFormValid()}
                  title={!isFormValid() ? "Please fill in all required fields" : ""}
                >
                  Save
                </button>
                <div className="tooltip-container">
                  {!isFormValid() && <span className="tooltip-text">Please fill in all required fields</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflowY: "auto",
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "2.5rem",
  borderRadius: "8px",
  width: "70%",
  height: "80vh",
  overflowY: "auto",
  position: "relative",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  backgroundColor: "black",
  borderRadius: "50%",
  color: "white",
  border: "none",
  fontWeight: 'bold',
};

export default TooltipEditor;

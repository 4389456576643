// frontend/src/pages/Upload.js
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { uploadImage } from "../services/api";
// import './css/upload'
import "./css/upload.css";

function Upload({onSave}) {
  const [tooltip, setTooltip] = useState({
    title: "",
    subtitle: "",
    description: "",
    links: ["", ""],
    iconStyle: "",
    size: "",
    color: "",
    innerImage: ''
  });
  const [projectName, setProjectName] = useState('');
  const location = useLocation();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [preview, setPreview] = useState(null);
  const [tooltipPreferences, setTooltipPreferences] = useState({
    iconStyle: '',
    size: '',
    color: ''
  });
  const navigate = useNavigate();

  // const handleFileChange = (e) => setFile(e.target.files[0]);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    // Create preview URL for the selected image
    const previewUrl = URL.createObjectURL(selectedFile);
    setPreview(previewUrl);
  };

  // Clean up preview URL when component unmounts
  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  const handleUpload = async () => {
    const image = await uploadImage(file);
    navigate(`/edit/${image._id}`);
  };



  const handleTooltipPreferenceChange = (name, value) => {
    setTooltipPreferences(prev => ({ ...prev, [name]: value }));
  };

  const isFormValid = () => {
    return (
      tooltip.title.trim() !== "" &&
      tooltip.subtitle.trim() !== "" &&
      tooltip.iconStyle !== "" &&
      tooltip.size !== "" &&
      tooltip.color !== "" &&
      tooltip.links.every(link => link.trim() !== "")
    );
  };

  const handleChange = (e) =>
    setTooltip({ ...tooltip, [e.target.name]: e.target.value });
  const handleSave = () => {
    if (isFormValid()) {
      onSave(tooltip);
    } else {
      alert("Please fill in all required fields");
    }
  };


  return (
    <>
      <header className="home-page-header">
        <span style={{color:"grey"}}>Upload</span>
      </header>
      
      <div className="upload-page">
      <nav className="navbar">
        {location.pathname !== "/" && (
          <Link
            to="/"
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
            }}
          >
            {" "}
            Home
          </Link>
        )}
        {location.pathname !== "/upload" && (
          <Link
            to="/upload"
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
            }}
          >
            Upload
          </Link>
        )}
      </nav>
        <h1 style={{color:"grey"}}>Upload Image</h1>
        
<div className="project-name-input" style={{ marginBottom: '20px' }}>

</div>
        <div className="upload-card">
          <label htmlFor="imageUpload" className="upload-box">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              id="imageUpload"
              className="hidden-input"
            />
        {preview ? (
          <>
          <img 
            src={preview} 
            alt="Preview" 
            style={{
              maxWidth: '100%',
              maxHeight: '300px',
              objectFit: 'contain'
            }}
          />
            {/* <p style={{
              marginTop: '10px',
              fontSize: '14px',
              color: '#666'
            }}>
              {fileName}
            </p> */}


          </>
          
        ) : (
          <div className="upload-placeholder">
            <p>Drag and Drop image here</p>
            <p>or</p>
            <span>Upload from Computer</span>
          </div>
        )}
          </label>
          {/* ===================================================== */}

          {/* ===================================================== */}
          <div className="buttons">
            <button
              onClick={handleUpload}
              // disabled={!selectedImage}
              className="save-button"
            >
              Save
            </button>
          </div>
        </div>
        <div className="footer">
      <footer>
          <h5>© 2024 ImageUploader Pro. All rights reserved by Mike Faircloth.</h5>
        </footer>
      </div>
        {/* <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button> */}
      </div>
    </>
  );
}

export default Upload;


import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Upload from './pages/Upload';
import EditImage from './pages/EditImage';
import ShareImage from './pages/ShareImage';
import './App.css';

// function Navigation() {
//   const location = useLocation();
  
//   return (
//     <nav className='navbar'>
//       {location.pathname !== '/' && <Link to="/" style={{backgroundColor:"black",color:"white",borderRadius:"10px"}}> Home</Link>}
//       {location.pathname !== '/upload' && <Link to="/upload" style={{backgroundColor:"black",color:"white",borderRadius:"10px"}}>Upload</Link>}
//     </nav>
//   );
// }


function App() {
    return (
        <Router>
            {/* <Navigation /> */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/upload" element={<Upload />} />
                <Route path="/edit/:imageId" element={<EditImage />} />
                <Route path="/share/:imageId" element={<ShareImage />} /> 
            </Routes>
        </Router>
    );
}

export default App;

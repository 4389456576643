// frontend/src/services/api.js
import axios from 'axios';

const API_URL = 'https://keen-energy-production.up.railway.app/api/images';

export const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    const response = await axios.post(`${API_URL}/upload`, formData);
    return response.data.image;
};

// export const uploadImage = async (formData) => {
//     const config = {
//         headers: {
//             'Content-Type': 'multipart/form-data'
//         }
//     };
    
//     const response = await axios.post(`${API_URL}/upload`, formData, config);
//     return response.data.image;
// };

export const addTooltip = async (imageId, tooltip) => {
    const response = await axios.post(`${API_URL}/tooltip`, { imageId, tooltip });
    return response.data.image;
};

export const getImageWithTooltips = async (imageId) => {
    const response = await axios.get(`${API_URL}/${imageId}`);
    return response.data.image;
};

export const getAllImages = async () => {
    const response = await axios.get(API_URL);
    return response.data.images;
};

export const getImage = async (imageId) => {
    const response = await axios.get(`${API_URL}/${imageId}`);
    return response.data.image;
};

export const updateImageDetails = async (imageId, details) => {
    await axios.put(`${API_URL}/${imageId}/details`, details);
};

export const updateFinishDetails = async (imageId, details) => {
    await axios.put(`${API_URL}/${imageId}/finish`, details);
};


export const updateTooltipPosition = async (tooltipId, { x, y }) => {
    const response = await axios.put(`${API_URL}/tooltips/${tooltipId}`, { x, y });
    return response.data;
};
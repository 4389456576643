
import React, { useEffect } from 'react';

function TooltipModal({ tooltip, imageUrl, onClose }) {

    const isMobile = window.innerWidth <= 768;
const responsiveStyles = isMobile ? mobileStyles : {};

    useEffect(() => {
      console.log("tooltip data is", tooltip.innerImage);})
    return (
        <div style={modalStyle}>
            <div style={modalContentStyle}>
                <button style={closeButtonStyle} onClick={onClose}><b>X</b></button>
                <div style={contentContainerStyle}>
                {tooltip.innerImage ? (
            <img src={`https://keen-energy-production.up.railway.app${tooltip.innerImage}`} alt="Uploaded Tooltip" style={imageStyle} />
          ) : (
            <p>No image provided</p>
          )}
                    {/* <img src={imageUrl} alt="Tooltip" style={imageStyle} /> */}
                    {/* <div style={infoStyle}>
                        <h2 style={titleStyle}>{tooltip.title}</h2>
                        <h4 style={subtitleStyle}>{tooltip.subtitle}</h4>
                        <p style={descriptionStyle}>{tooltip.description}</p>
                        <div style={linkContainerStyle}>
                            <a href={tooltip.links[0]} target="_blank" rel="noopener noreferrer" style={linkStyle}>
                                More Info
                            </a>
                            <a href={tooltip.links[1]} target="_blank" rel="noopener noreferrer" style={linkStyle}>
                                Contact Us
                            </a>
                        </div>
                    </div> */}
                    <div style={infoStyle}>
    <div style={titleContainerStyle}>
        <h2 style={titleStyle}>{tooltip.title}</h2>
        <h4 style={subtitleStyle}>{tooltip.subtitle}</h4>
    </div>
    <p style={descriptionStyle}>{tooltip.description}</p>
    <div style={linkContainerStyle}>
 <a 
    href={tooltip.links[0].startsWith('http') ? tooltip.links[0] : `https://${tooltip.links[0]}`} 
    target="_blank" 
    rel="noopener noreferrer" 
    style={linkStyle}
>
    More Info
</a>

<a 
    href={tooltip.links[1].startsWith('http') ? tooltip.links[1] : `https://${tooltip.links[1]}`} 
    target="_blank" 
    rel="noopener noreferrer" 
    style={linkStyle}
>
    Contact Us
</a>
    </div>
</div>
                </div>
            </div>
        </div>
    );
}

const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const modalContentStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '80%',
    maxWidth: '800px',
    maxHeight: '90vh',
    overflowY: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
};

const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    fontWeight: 'bold',
};

const contentContainerStyle = {
    display: 'flex',
    gap: '20px',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: '15px'
    }
    // alignItems: 'center',
};

const imageStyle = {
    width: '50%',
    minHeight: '400px',
    maxHeight: '90vh',
    objectFit: 'contain',
    borderRadius: '8px',
    '@media (max-width: 768px)': {
        width: '100%',
        minHeight: '200px'
    }
};

const infoStyle = {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Changed from space-evenly
    minHeight: '400px', // Added to ensure full height usage
    '@media (max-width: 768px)': {
        width: '100%',
        minHeight: 'auto',
        padding: '10px'
    }
};

const titleContainerStyle = {
    marginBottom: '2rem' // Adds space between title section and description
};

const titleStyle = {
    marginTop: '0px',    // Added to ensure no extra space from top
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '5px',  // Reduced from 10px
};

const subtitleStyle = {
    fontSize: '18px',
    color: '#555',
    marginTop: '0px',    // Added to ensure no extra space from top
    marginBottom: '3rem'  // Keep this the same to maintain spacing with description
};

const descriptionStyle = {
    fontSize: '16px',
    marginBottom: '10rem',
};

const linkContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop:"3rem",
    marginBottom: "0px"
};

const linkStyle = {
    marginBottom: 0,
    fontSize: '16px',
    color: '#007bff',
    textDecoration: 'none',
};

const mobileStyles = {
    modalContentStyle: {
        width: '95%',
        padding: '15px',
    },
    contentContainerStyle: {
        flexDirection: 'column',
        gap: '15px',
    },
    imageStyle: {
        width: '100%',
        minHeight: '200px',
    },
    infoStyle: {
        width: '100%',
        minHeight: 'auto',
        padding: '10px',
    },
    linkContainerStyle: {
        marginTop: '1.5rem',
    }
};

export default TooltipModal;

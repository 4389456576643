
import React, { useState, useEffect,  } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import TooltipEditor from '../components/ToolTipEditor';
import TooltipModal from '../components/TooltipModal';
import Draggable from 'react-draggable';
import { addTooltip, getImageWithTooltips, updateFinishDetails, updateImageDetails, updateTooltipPosition } from '../services/api'; // Import API function
import './css/EditImage.css';

function EditImage() {
    const { imageId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [currentImage, setCurrentImage] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedTooltip, setSelectedTooltip] = useState(null);
    const [projectName, setProjectName] = useState(''); // State for project name
    const [stylePreferences, setStylePreferences] = useState({
        iconStyle: '',
        size: '',
        color: ''
    });

    useEffect(() => {
        const fetchImage = async () => {
            const updatedImage = await getImageWithTooltips(imageId);
            setCurrentImage(updatedImage);
            setProjectName(updatedImage.projectName || ''); // Initialize project name
            setStylePreferences({
                iconStyle: updatedImage.iconStyle || '',
                size: updatedImage.size || '',
                color: updatedImage.color || ''
            });
        };
        fetchImage();
    }, [imageId]);

    const handleSaveDetails = async () => {
        await updateImageDetails(imageId, { projectName, stylePreferences });
        const updatedImage = await getImageWithTooltips(imageId); // Fetch updated data
        setCurrentImage(updatedImage);
        navigate('/')
    };

    const handleFinishDetails = async () => {
        await updateFinishDetails(imageId, { finished:true });
        const updatedImage = await getImageWithTooltips(imageId); // Fetch updated data
        setCurrentImage(updatedImage);
        navigate('/')
    };


    const handleImageClick = (e) => {
        if (!selectedTooltip) {
            const image = e.target;
            const { offsetX, offsetY } = e.nativeEvent;
            console.log("Image clicked at:", offsetX, offsetY);
            console.log("Image width:", image.width);
            console.log("Image height:", image.height);
            
     
            // Calculate percentage of the position relative to image size
            const xPercent = (offsetX / image.width) * 100;
            const yPercent = (offsetY / image.height) * 100;
            console.log("Percentage position:", xPercent, yPercent);

     
            setSelectedPosition({ x: xPercent, y: yPercent });
        }
    };

    const handleTooltipSave = async (tooltipData) => {
        // Save the tooltip with percentage positions
        await addTooltip(currentImage._id, { ...selectedPosition, ...tooltipData });
        const updatedImage = await getImageWithTooltips(currentImage._id);
        setCurrentImage(updatedImage);
        setSelectedPosition(null);
    };

    const handleTooltipClick = (e, tooltip) => {
        e.stopPropagation();
        setSelectedTooltip(tooltip);
    };

    const handleCloseEditor = () => {
        setSelectedPosition(null);
    };

    const handleCloseModal = () => {
        setSelectedTooltip(null);
    };
    
    const handleDragStop = async (e, data, tooltip) => {
        // Use e.target to get the div element that contains the image
        const div = e.target.closest('div');  // Ensure you're getting the parent div, not the image
    
        // Get the bounding client rect of the div to get its position relative to the viewport
        const divRect = div.getBoundingClientRect();
        const divWidth = divRect.width;
        const divHeight = divRect.height;
        const divLeft = divRect.left;
        const divTop = divRect.top;
    
        // Log for debugging to see the actual position
        console.log("Div width:", divWidth);
        console.log("Div height:", divHeight);
        console.log("Tooltip dragged to position:", data.x, data.y);
    
        // Calculate new positions relative to the div (by subtracting div's left and top positions)
        const newX = data.x - divLeft;  // x position relative to the div
        const newY = data.y - divTop;   // y position relative to the div
    
        // Log the new position of the tooltip relative to the div
        console.log("Tooltip position relative to div:", newX, newY);
    
        // Now convert these pixel positions into percentages relative to the div's dimensions
        const xPercent = (newX / divWidth) * 100;  // Calculate the percentage of the div's width
        const yPercent = (newY / divHeight) * 100;  // Calculate the percentage of the div's height
    
        // Log the position of the tooltip as a percentage
        console.log("Tooltip position in percentage:", xPercent, yPercent);
    
        // Create an updated tooltip object with the percentage-based position
        const updatedTooltip = {
            ...tooltip,
            x: data.x,  // Store the x position as a percentage
            y: data.y   // Store the y position as a percentage
        };
    
        // Call the API to update the tooltip's position in the database
        await updateTooltipPosition(tooltip._id, updatedTooltip);
    
        // Optionally update the state
        // setCurrentImage(prev => ({
        //     ...prev,
        //     tooltips: prev.tooltips.map(t => (t.id === tooltip.id ? updatedTooltip : t))
        // }));
        setCurrentImage(prev => ({
            ...prev,
            tooltips: prev.tooltips.map(t => 
                t._id === tooltip._id ? updatedTooltip : t
            )
        }));
    };
    
        

    return (
        <div>
            <div>
                <nav className="navbar">
                    {location.pathname !== "/" && (
                        <Link to="/" style={{ backgroundColor: "black", color: "white", borderRadius: "10px" }}>Home</Link>
                    )}
                    {location.pathname !== "/upload" && (
                        <Link to="/upload" style={{ backgroundColor: "black", color: "white", borderRadius: "10px" }}>Upload</Link>
                    )}
                </nav>
            </div>
            <div className='mainWrap'>
                <div className='editImageMain'>
                    <div className='editTop'>
                        <input
                            type="text"
                            placeholder="Enter Project Name Here"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            style={{ padding: '0.75rem', border: "none", width: '100%', maxWidth: '20rem', minWidth: '1rem', overflow: 'hidden', fontSize: '1.5rem', textAlign: 'left' }}
                        />
                        <button className='finish-button' onClick={handleFinishDetails}>Finish</button>
                    </div>
                    {currentImage && (
 <div onDoubleClick={handleImageClick} style={{ position: 'relative', borderRadius: '1rem', overflow: 'hidden' }}>
        <img src={`https://keen-energy-production.up.railway.app${currentImage.url}`} alt="Uploaded" style={{ width: "100%", maxHeight: "80vh", objectFit: "contain", display: "block", borderRadius: "1rem", marginTop: "1rem", cursor: 'pointer' }} />
        {currentImage.tooltips.map((tooltip, i) => (
   <Draggable
   positionOffset={{ x: -50, y: -40 }}
   key={i}
//    position={{ x: tooltip.x, y: tooltip.y }}
position={{
    x: Math.min(Math.max(0, tooltip.x), 100), // Ensure x is between 0 and 100
    y: Math.min(Math.max(0, tooltip.y), 100)  // Ensure y is between 0 and 100
}}
   onStop={(e, data) => handleDragStop(e, data, tooltip)}
   
>
        <div
            className="tooltipp"
            key={i}
            onDoubleClick={(e) => handleTooltipClick(e, tooltip)}
            style={{
                position: 'absolute',
                left: `calc(${tooltip.x}% - 10px)`, // Convert percentage to pixel-based positioning
                top: `calc(${tooltip.y}% - 10px)`,  // Convert percentage to pixel-based positioning
                // fontSize: '1.5rem',
                color: stylePreferences.color || 'black',
                cursor: 'pointer',
            }}
        >
            <span style={{fontSize: stylePreferences.size === "small" ? "0.75rem" : stylePreferences.size === "medium" ? "1rem" : "1.25rem",}}
             className="material-symbols-outlined">{stylePreferences.iconStyle || 'radio_button_checked'}</span>
        </div>
     </Draggable>    
    ))}
    </div>
)}

                    {selectedPosition && !selectedTooltip && (
                        <TooltipEditor onSave={handleTooltipSave} onClose={handleCloseEditor} imageUrl={`http://localhost:3001${currentImage.url}`} />
                    )}
                    {selectedTooltip && (
                        <TooltipModal tooltip={selectedTooltip} imageUrl={`https://keen-energy-production.up.railway.app${currentImage.url}`} onClose={handleCloseModal} />
                    )}
                    <div className='editBottom'>
                        <button className='save-button' onClick={handleSaveDetails}>Save</button>
                    </div>
                    <div className="tooltip-options">
                        <div className="radio-group">
                            <label><b>Choose Hotspot Style:</b></label>
                            <div className="icon-options">
                                <span className="material-symbols-outlined" onClick={() => setStylePreferences({ ...stylePreferences, iconStyle: "radio_button_checked" })} style={{ cursor: 'pointer', color: stylePreferences.iconStyle === "radio_button_checked" ? "black" : "grey" }}>radio_button_checked</span>
                                <span className="material-symbols-outlined" onClick={() => setStylePreferences({ ...stylePreferences, iconStyle: "radio_button_unchecked" })} style={{ cursor: 'pointer', color: stylePreferences.iconStyle === "radio_button_unchecked" ? "black" : "grey" }}>radio_button_unchecked</span>
                            </div>
                        </div>
                        <div className="radio-group">
                            <label><b>Choose Hotspot Size:</b></label>
                            <div className="radio-options">
                                {["small", "medium", "large"].map(size => (
                                    <label key={size}>
                                        <input type="radio" name="size" value={size} checked={stylePreferences.size === size} onChange={() => setStylePreferences({ ...stylePreferences, size })} />
                                        {size.charAt(0).toUpperCase() + size.slice(1)}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="color-group">
                            <label><b>Choose Hotspot Color:</b></label>
                            <div className="color-options">
                                {['black', 'grey', 'lightgreen'].map(color => (
                                    <button key={color} className={`color-button ${stylePreferences.color === color ? 'selected' : ''}`} style={{ backgroundColor: color, border: "none", cursor: "pointer", borderRadius: '50%', width: '30px', height: '30px', margin: '5px' }} onClick={() => setStylePreferences({ ...stylePreferences, color })} />
                                ))}
                                <input
                                    type="color"
                                    value={stylePreferences.color}
                                    onChange={(e) => setStylePreferences({ ...stylePreferences, color: e.target.value })}
                                    style={{
                                      background:"none", border: "none", cursor: "pointer", borderRadius: '50%', width: '30px', height: '30px', margin: '5px' 
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <p>Double click on image to place hotspot, then double click on the hot spot to view it.</p>
                        <p><b>Click on and drag to move the hotspots.</b></p>
                    </div>
                    <div className="footer">
                        <footer>
                            <h5>© 2024 ImageUploader Pro. All rights reserved by Mike Faircloth.</h5>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditImage;
